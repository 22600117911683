import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cookieCutter from "cookie-cutter";
import { getUserProfile } from "./../../store/actions/Users";

export default () => {
  const dispatch = useDispatch();

  const [token, setToken] = useState(
    cookieCutter && cookieCutter.get ? cookieCutter.get("token") : null
  );
  const [isLoggesIn, setIsLoggesIn] = useState(!!token);

  // useEffect(() => {
  //   if (isLoggesIn) {
  //     dispatch(getUserProfile());
  //   }
  // }, [isLoggesIn]);

  const usersData = useSelector((state) => {
    return state.UsersReducer;
  });

  useEffect(() => {
    setIsLoggesIn(!!token);
  }, [token]);

  useEffect(() => {
    if (usersData && usersData.token) {
      cookieCutter.set("token", usersData.token);
      setToken(usersData.token);
    } else if (!usersData.isLogin) {
      cookieCutter.set("token", cookieCutter.get("token"), {
        expires: new Date(0),
      });
      setToken(null);
    }
  }, [usersData]);

  return isLoggesIn;
};
