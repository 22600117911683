import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import NextLink from "../NextLink";
import Link from "@material-ui/core/Link";
import useTranslation from "next-translate/useTranslation";
import {
  SIGN_IN,
  SIGN_UP,
  CREATE_LISTING,
  FOR_RENT_PAGE_ROUTE,
  FOR_BUY_PAGE_ROUTE,
  CONDO_FOR_BUY_PAGE_ROUTE,
  CONDO_FOR_RENT_PAGE_ROUTE,
  HDB_FOR_BUY_PAGE_ROUTE,
  HDB_FOR_RENT_PAGE_ROUTE,
  ROOM_FOR_RENT_PAGE_ROUTE,
  CONDO_ROUTE,
} from "../../config/routes";
import useStyles from "./style";
import { useRouter } from "next/router";
import NavMenu from "./../NavMenu";
import SearchBar from "./../SearchBar";
import MenuDrawer from "./../MenuDrawer";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import useUsersStatus from "./../../states/users";
import screenSize from "./../../states/screen";
import UserProfile from "./UserProfile";
import Language from "./Language";
import Menu from "./../Menu";
import { getUserProfile } from '../../../store/actions/Users'

const title = "Property Glassdoor";

export default function Header({ city }) {
  const classes = useStyles();
  const router = useRouter();
  const isLoggesIn = useUsersStatus();
  const isDesktop = screenSize();
  const dispatch = useDispatch();
  const [selectedType, setSeletedType] = useState("Buy");
  const [autocompleteData, setAutocompleteData] = useState("");
  const { t } = useTranslation("common");
  const fetchSuggestions = () => {};

  const usersData = useSelector((state) => {
    return state.UsersReducer;
  });

  useEffect(() => {
    if (isLoggesIn) {
      dispatch(getUserProfile());
    }
  }, [isLoggesIn]);

  return (
    <Container
      maxWidth={false}
      component="header"
      disableGutters={true}
      className={classes.toolbar}
    >
      <Container maxWidth={false}>
        <Toolbar
          disableGutters={true}
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          {!isDesktop ? (
            <MenuDrawer user={usersData.userDetails} showImage={true} />
          ) : null}
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            className={classes.toolbarTitle}
          >
            <NextLink href="/">
              <a className={classes.logo}>Property Glassdoor</a>
            </NextLink>
          </Typography>
          <Hidden only={["xs", "sm"]}>
            <Toolbar component="nav" className={classes.toolbarSecondary}>
              {city ? (
                <Fragment>
                  <NextLink href={`/${city}${FOR_BUY_PAGE_ROUTE}`}>
                    <a className={classes.navLink}>{t("buy")}</a>
                  </NextLink>
                  <NextLink href={`/${city}${FOR_RENT_PAGE_ROUTE}`}>
                    <a className={classes.navLink}>{t("rent")}</a>
                  </NextLink>
                  <NextLink href={`/${city}${ROOM_FOR_RENT_PAGE_ROUTE}`}>
                    <a className={classes.navLink}> {t("room_rental")}</a>
                  </NextLink>
                  <NextLink href={CONDO_ROUTE(city)}>
                    <a className={classes.navLink}> {t("condos")}</a>
                  </NextLink>
                </Fragment>
              ) : (
                <Fragment>
                  <Menu
                    label={t("buy")}
                    items={[
                      {
                        label: t("singapore"),
                        route: "/singapore",
                        heading: true,
                      },
                      {
                        label: t("condo_for_sale"),
                        route: `/singapore${CONDO_FOR_BUY_PAGE_ROUTE}`,
                      },
                      {
                        label: t("hdb_for_sale"),
                        route: `/singapore${HDB_FOR_BUY_PAGE_ROUTE}`,
                      },
                    ]}
                  />
                  <Menu
                    label={t("rent")}
                    items={[
                      {
                        label: t("singapore"),
                        route: "/singapore",
                        heading: true,
                      },
                      {
                        label: t("condo_for_rent"),
                        route: `/singapore${CONDO_FOR_RENT_PAGE_ROUTE}`,
                      },
                      {
                        label: t("hdb_for_rent"),
                        route: `/singapore${HDB_FOR_RENT_PAGE_ROUTE}`,
                      },
                    ]}
                  />
                  <Menu
                    label={t("condos")}
                    items={[
                      {
                        label: t("singapore"),
                        route: "/singapore",
                        heading: true,
                      },
                      {
                        label: t("condos"),
                        route: CONDO_ROUTE("singapore"),
                      },
                    ]}
                  />
                </Fragment>
              )}
              <NextLink href={CREATE_LISTING}>
                <a className={classes.navLink}>{t("post_listing")}</a>
              </NextLink>
            </Toolbar>
          </Hidden>
          <Toolbar
            component="div"
            disableGutters={!isDesktop}
            className={classes.menuBar}
          >
            <Language />
            <Hidden only={["xs", "sm"]}>
              {!isLoggesIn ? null : (
                <UserProfile user={usersData.userDetails} />
              )}
            </Hidden>
            {isDesktop ? <MenuDrawer user={usersData.userDetails} /> : null}
          </Toolbar>
        </Toolbar>
      </Container>
    </Container>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
