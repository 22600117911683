import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "axios";
import {
  GET_PROPERTIES,
  CREATE_PROPERTY,
  GET_PROPERTY,
  UPDATE_PROPERTY,
  UPLOAD_PROPERTY_MEDIA,
  GET_SELECTED_PROPERTIES,
  CREATE_PROPERTY_UNIT,
  CREATE_PROPERTY_BLOCK,
} from "../constants";
import {
  createPropertySuccess,
  updatePropertySuccess,
  uploadMediaSuccess,
  getPropertiesSuccess,
  getPropertySuccess,
  getSelectedPropertiesSuccess,
  createPropertyUnitSuccess,
  createPropertyBlockSuccess,
} from "./../actions/Property";
import Fetch from "../../libs/fetch";
import {
  PROPERTY_URL,
  MEDIA_URL,
  UNIT_URL,
  BLOCK_URL,
  PROPERTY_DETAIL_URL,
} from "../api";

export function* getProperties(options) {
  try {
    const response = yield call(Fetch, {
      url: PROPERTY_URL,
      method: "GET",
      headers: null,
      queryParams: options.filter,
      useCache: true,
    });
    yield put(getPropertiesSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* getProperty(options) {
  try {
    const response = yield call(Fetch, {
      url: PROPERTY_DETAIL_URL,
      method: "GET",
      headers: null,
      queryParams: { id: options.id },
      useCache: true,
    });
    yield put(getPropertySuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* updateProperty(options) {
  try {
    const response = yield call(Fetch, {
      url: PROPERTY_URL,
      method: "PUT",
      headers: null,
      body: JSON.stringify(options.data),
    });
    yield put(updatePropertySuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* createProperty(options) {
  try {
    const response = yield call(Fetch, {
      url: PROPERTY_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(options.data),
    });
    const updatedResponse = {
      ...options.data,
      propertyId: response._id,
    };
    yield put(createPropertySuccess(updatedResponse));
  } catch (error) {
    console.log("error", error);
    //yield put(loginError(error));
  }
}

export function* uploadMedia(options) {
  console.log(options.data, "options.data");
  try {
    const mediaList = [];
    const youtubeUrls = [];
    const virtualUrl = [];
    for (let i in options.data.images) {
      let file = options.data.images[i];
      const fileNameArr = file.name.split(".");
      const ext = fileNameArr[fileNameArr.length - 1];
      const time = new Date().getTime();
      const newFileName = `${options.data.propertyId}/${options.data.propertyId}_${time}.${ext}`;

      let formData = new FormData();
      formData.append("file", file, newFileName);

      const response = yield call(Fetch, {
        url: MEDIA_URL,
        method: "POST",
        body: JSON.stringify({
          propertyId: options.data.propertyId,
          fileType: file["type"],
          fileName: newFileName,
        }),
      });

      yield Axios.put(response.data.signedRequest, file, {
        "Content-Type": file["type"],
      });
      mediaList.push({ url: response.data.url });
    }

    for (let j in options.data.youtubeUrls) {
      if (options.data.youtubeUrls[j]) {
        youtubeUrls.push(options.data.youtubeUrls[j]);
      }
    }

    for (let j in options.data.virtualTours) {
      if (options.data.virtualTours[j]) {
        virtualUrl.push(options.data.virtualTours[j]);
      }
    }

    // Save Youtube
    // yield call(Fetch, {
    //   url: MEDIA_URL,
    //   method: "PUT",
    //   body: JSON.stringify({
    //     propertyId: options.data.propertyId,
    //     images: mediaList,
    //     youtubeUrls,
    //     virtualUrl,
    //   }),
    // });

    yield put(
      uploadMediaSuccess({
        images: mediaList,
        youtubeUrls,
        virtualUrl,
      })
    );
  } catch (error) {
    console.log(error, "error");
  }
}

export function* getSelectedProperty(options) {
  try {
    const response = yield call(Fetch, {
      url: PROPERTY_URL,
      method: "GET",
      headers: null,
      queryParams: { ids: options.id },
    });
    yield put(getSelectedPropertiesSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* createPropertyUnit(options) {
  try {
    let mediaList = [];

    if (options.data.images && options.data.images.length) {
      for (let i in options.data.images) {
        if (options.data.images[i] && options.data.images[i].url) {
          mediaList.push(options.data.images[i]);
          continue;
        }
        let file = options.data.images[i];
        const fileNameArr = file.name.split(".");
        const ext = fileNameArr[fileNameArr.length - 1];
        const time = new Date().getTime();
        const newFileName = `${options.data.propertyId}/${options.data.propertyId}_${time}.${ext}`;

        let formData = new FormData();
        formData.append("file", file, newFileName);

        const response = yield call(Fetch, {
          url: MEDIA_URL,
          method: "POST",
          body: JSON.stringify({
            propertyId: options.data.propertyId,
            fileType: file["type"],
            fileName: newFileName,
          }),
        });

        yield Axios.put(response.data.signedRequest, file, {
          "Content-Type": file["type"],
        });
        mediaList.push({ url: response.data.url });
      }
    } else {
      mediaList = options.data.images || [];
    }

    const response = yield call(Fetch, {
      url: UNIT_URL,
      method: "PUT",
      headers: null,
      body: JSON.stringify({ ...options.data, images: mediaList }),
    });
    yield put(createPropertyUnitSuccess(response));
  } catch (error) {
    console.log(error, "error");
  }
}

export function* createPropertyBlocks(options) {
  try {
    const response = yield call(Fetch, {
      url: BLOCK_URL,
      method: "PUT",
      headers: null,
      body: JSON.stringify(options.data),
    });
    yield put(createPropertyBlockSuccess(response));
  } catch (error) {
    console.log(error, "error");
  }
}

export default function* propertySaga() {
  yield takeLatest(GET_PROPERTIES, getProperties);
  yield takeLatest(GET_SELECTED_PROPERTIES, getSelectedProperty);
  yield takeLatest(GET_PROPERTY, getProperty);
  yield takeLatest(CREATE_PROPERTY, createProperty);
  yield takeLatest(UPDATE_PROPERTY, updateProperty);
  yield takeLatest(UPLOAD_PROPERTY_MEDIA, uploadMedia);
  yield takeLatest(CREATE_PROPERTY_UNIT, createPropertyUnit);
  yield takeLatest(CREATE_PROPERTY_BLOCK, createPropertyBlocks);
}
