const PATH = "/api";
const isProd = process.env.NODE_ENV === 'production'
export const HOST = isProd ? `http://propertyglassdoor.com` : 'http://localhost:3000';
export const LISTING_URL = `${PATH}/listings`;
export const LISTING_DETAIL_URL = `${PATH}/listing`;
export const LOGIN_URL = `${PATH}/users/login`;
export const REGISTER_URL = `${PATH}/users/register`;
export const USER_PROFILE_URL = `${PATH}/users/me`;
export const USER_DETAIL = `${PATH}/users`;
export const MEDIA_URL = `${PATH}/media`;
export const SOCIAL_PAGE_URL = `${PATH}/socialPage`;
export const PROPERTY_URL = `${PATH}/properties`;
export const PROPERTY_DETAIL_URL = `${PATH}/property`;
export const UNIT_URL = `${PATH}/units`;
export const BLOCK_URL = `${PATH}/blocks`;
export const POI_PAGE_URL = `${PATH}/poi`;
export const LEADS_PAGE_URL = `${PATH}/leads`;
export const DEVELOPER_PAGE_URL = `${PATH}/developer`;
export const ALL_USERS = `${PATH}/users/all`;
export const AGENCY_PAGE_URL = `${PATH}/agency`;
export const REVIEW_PAGE_URL = `${PATH}/reviews`;
