import { HOST } from "./routes";

export const FACEBOOK_APP_ID = "153554940088686";
export const languageAlternates = [
  {
    hrefLang: "x-default",
    href: `${HOST}`,
  },
  {
    hrefLang: "en_US",
    href: `${HOST}/en`,
  },
  {
    hrefLang: "zh-Hans",
    href: `${HOST}/zh`,
  },
];

export const generateAlternateLanguages = (url) => {
  return languageAlternates.map((language) => ({
    ...language,
    href: `${language.href}${url}`,
  }));
};

export const robotsProps = {
  notranslate: true,
};

export const additionalMetaTags = [
  {
    property: "application-name",
    content: "PropertGlassdoor",
  },
  {
    httpEquiv: "x-ua-compatible",
    content: "IE=edge; chrome=1",
  },
];

export const facebook = {
  appId: FACEBOOK_APP_ID,
};

export const twitter = {
  handle: "@handle",
  site: "@site",
  cardType: "summary_large_image",
};

export const additionalLinkTags = [
  {
    rel: "icon",
    href: `${HOST}/favicon.ico`,
  },
  {
    rel: "apple-touch-icon",
    href: `${HOST}/touch-icon-ipad.jpg`,
    sizes: "76x76",
  },
  {
    rel: "manifest",
    href: `${HOST}/manifest.json`,
  },
];

export const title = {
  home: "property_glassdoor_real_estate_listings_homes_for_sale_homes_for_rent_room_rental_housing_data_post_listing_for_free",
  login: "login_page_title",
  register: "register_page_title",
  "forgot-password": "forgot_password_page_title",
  singapore: {
    home: "property_glassdoor_singapore_real_estate_listings_homes_for_sale_homes_for_rent_room_rental_housing_data_post_listing_for_free",
    sale: {
      condo: "condominium_for_sale_in_singapore",
      hdb: "hdb_for_sale_in_singapore",
      default: "property_for_sale_in_singapore",
      condo_at: "singapore_condo_for_sale_at_seo_title",
      hdb_at: "singapore_hdb_for_sale_at_seo_title",
      property_at: "singapore_property_for_sale_at_seo_title",
    },
    rent: {
      condo: "condominium_for_sale_in_singapore",
      hdb: "hdb_for_rent_in_singapore",
      default: "property_for_rent_in_singapore",
      condo_at: "singapore_condo_for_rent_at_seo_title",
      hdb_at: "singapore_hdb_for_rent_at_seo_title",
      property_at: "singapore_property_for_rent_at_seo_title",
    },
    "room-rental": {
      condo: "condominium_for_room_rental_in_singapore",
      hdb: "hdb_for_room_rental_in_singapore",
      default: "property_for_room_rental_in_singapore",
    },
    condo: "condominium_in_singapore",
    postProperty: "singapore_post_property_for_free_seo_title",
  },
};

export const description = {
  home: "default_seo_description",
  login: "default_seo_description",
  register: "default_seo_description",
  "forgot-password": "default_seo_description",
  singapore: {
    home: "singapore_home_seo_description",
    sale: {
      condo: "singapore_sale_condo_seo_description",
      hdb: "singapore_sale_hdb_seo_description",
      default: "singapore_sale_property_seo_description",
      condo_at: "singapore_condo_for_sale_at_seo_description",
      hdb_at: "singapore_hdb_for_sale_at_seo_description",
      property_at: "singapore_property_for_sale_at_seo_description",
    },
    rent: {
      condo: "singapore_rent_condo_seo_description",
      hdb: "singapore_rent_hdb_seo_description",
      default: "singapore_rent_property_seo_description",
      condo_at: "singapore_condo_for_rent_at_seo_description",
      hdb_at: "singapore_hdb_for_rent_at_seo_description",
      property_at: "singapore_property_for_rent_at_seo_description",
    },
    "room-rental": {
      condo: "singapore_room_rental_condo_seo_description",
      hdb: "singapore_room_rental_hdb_seo_description",
      default: "singapore_room_rental_property_seo_description",
    },
    condo: "singapore_condos_seo_description",
    postProperty: "singapore_post_property_for_free_seo_description",
  },
};

export const keywords = {
  home: "Real estate, property for sale, property for rent, property listings, Post Listing for Free, Post Property For Free, No Cost Property Portal",
  singapore: {
    home: "Singapore property, Singapore real estate, property for sale, property for rent, Singapore property listings, No Cost Property Portal",
    sale: "Singapore property, Singapore real estate, property for sale, property glassdoor, Singapore property listings, No Cost Property Portal",
    rent: "Singapore property, Singapore real estate, property for rent, property glassdoor, Singapore property listings, No Cost Property Portal",
    condo:
      "Singapore property, Condominium in Singapore, Condos in Singapore, Property Glassdoor, No Cost Property Portal",
    "room-rental":
      "Singapore property, Singapore real estate, property room rental, property glassdoor, Singapore property listings, No Cost Property Portal",
  },
  sydney: {
    home: "Sydney property, Sydney real estate, property for sale, property for rent, Sydney property listings, No Cost Property Portal",
    sale: "Sydney property, Sydney real estate, property for sale, property glassdoor, Sydney property listings, No Cost Property Portal",
    rent: "Sydney property, Sydney real estate, property for rent, property glassdoor, Sydney property listings, No Cost Property Portal",
    condo:
      "Sydney property, Condominium in Sydney, Condos in Sydney, Property Glassdoor",
  },
};
