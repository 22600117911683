import {
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_ERROR,
  SAVE_REVIEW,
  SAVE_REVIEW_SUCCESS,
} from "../constants";

export const getReviews = (options) => ({
  type: GET_REVIEWS,
  options,
});

export const getReviewsSuccess = (data) => ({
  type: GET_REVIEWS_SUCCESS,
  data,
});

export const saveReview = (data) => ({
  type: SAVE_REVIEW,
  data,
});

export const getReviewsError = (message) => ({
  type: GET_REVIEWS_ERROR,
  message,
});

export const saveReviewSuccess = (data) => ({
  type: SAVE_REVIEW_SUCCESS,
  data,
});
