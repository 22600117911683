import {
  CREATE_LEAD,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_ERROR,
  GET_LEADS,
  GET_LEADS_SUCCESS,
} from "../constants";

export const generateLead = (data) => ({
  type: CREATE_LEAD,
  data,
});

export const generateLeadSuccess = (data) => ({
  type: CREATE_LEAD_SUCCESS,
  data,
});

export const generateLeadError = (data) => ({
  type: CREATE_LEAD_ERROR,
  data,
});

export const getLeads = (data) => ({
  type: GET_LEADS,
  data,
});

export const getLeadsSuccess = (data) => ({
  type: GET_LEADS_SUCCESS,
  data,
});
