import {
  CREATE_LEAD,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_ERROR,
  GET_LEADS,
  GET_LEADS_SUCCESS,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  leads: [],
  successMessage: null,
  errorMessage: null,
};

const LeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case CREATE_LEAD:
      return {
        ...state,
        isLoading: true,
        successMessage: null,
        errorMessage: null,
      };
    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: "Agent Contacted Successfully.",
        errorMessage: null,
      };
    case CREATE_LEAD_ERROR:
      return {
        ...state,
        isLoading: false,
        successMessage: null,
        errorMessage: action.data,
      };
    case GET_LEADS:
      return {
        ...state,
        isLoading: true,
        successMessage: null,
        errorMessage: null,
      };
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leads: action.data,
        successMessage: null,
        errorMessage: null,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default LeadsReducer;
