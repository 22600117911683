import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NextLink from "../NextLink";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import useTranslation from "next-translate/useTranslation";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  FOR_BUY_PAGE_ROUTE,
  FOR_RENT_PAGE_ROUTE,
  UNDER_CONSTRUCTION_PROPERTY,
  PRIVACY_POLICY,
  TERMS_OF_USE,
  TERMS_AND_CONDITION,
  COOKIE_POLICY,
  ABOUT_US,
  CONTACT_US,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  PINTEREST,
  CONDO_ROUTE,
  HOST,
  SIGN_UP,
  SIGN_IN,
  CONDO_FOR_BUY_PAGE_ROUTE,
  HDB_FOR_BUY_PAGE_ROUTE,
  CONDO_FOR_RENT_PAGE_ROUTE,
  HDB_FOR_RENT_PAGE_ROUTE,
  ROOM_FOR_RENT_PAGE_ROUTE,
} from "./../../config/routes";
import useUsersStatus from "./../../states/users";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: "#FFF",
    backgroundColor: "#515b6d",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function FooterComponent() {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const isLoggesIn = useUsersStatus();

  const footers = [
    {
      title: t("singapore_real_estate"),
      url: "/singapore",
      description: [
        {
          url: `/singapore${FOR_BUY_PAGE_ROUTE}`,
          label: t("singapore_property_for_sale"),
        },
        {
          url: `/singapore${UNDER_CONSTRUCTION_PROPERTY}`,
          label: t("singapore_under_construction_property"),
        },
        {
          label: t("condo_for_sale"),
          url: `/singapore${CONDO_FOR_BUY_PAGE_ROUTE}`,
        },
        {
          label: t("hdb_for_sale"),
          url: `/singapore${HDB_FOR_BUY_PAGE_ROUTE}`,
        },
        {
          url: CONDO_ROUTE("singapore"),
          label: t("condos_singapore"),
        },
      ],
    },
    {
      title: t("singapore_real_estate"),
      url: "/singapore",
      description: [
        {
          url: `/singapore${FOR_RENT_PAGE_ROUTE}`,
          label: t("singapore_property_for_rent"),
        },
        {
          label: t("condo_for_rent"),
          url: `/singapore${CONDO_FOR_RENT_PAGE_ROUTE}`,
        },
        {
          label: t("hdb_for_rent"),
          url: `/singapore${HDB_FOR_RENT_PAGE_ROUTE}`,
        },
        {
          label: t("rooms_for_rent"),
          url: `/singapore${ROOM_FOR_RENT_PAGE_ROUTE}`,
        },
      ],
    },
    // {
    //   title: t("sydney_real_estate"),
    //   url: "/sydney",
    //   description: [
    //     {
    //       url: `/sydney${FOR_BUY_PAGE_ROUTE}`,
    //       label: t("sydney_property_for_sale"),
    //     },
    //     {
    //       url: `/sydney${FOR_RENT_PAGE_ROUTE}`,
    //       label: t("sydney_property_for_rent"),
    //     },
    //     {
    //       url: `/sydney${UNDER_CONSTRUCTION_PROPERTY}`,
    //       label: t("sydney_under_construction_property"),
    //     },
    //     // {
    //     //   url: CONDO_ROUTE("sydney"),
    //     //   label: t("condos_sydney"),
    //     // },
    //   ],
    // },
    // {
    //   title: "",
    //   description: [
    //     { url: CONTACT_US, label: t("contact_us") },
    //     { url: ABOUT_US, label: t("about_us") },
    //     { url: PRIVACY_POLICY, label: t("privacy_policy") },
    //     { url: TERMS_OF_USE, label: t("terms_of_use") },
    //   ],
    // },
  ];

  const authLinks = isLoggesIn
    ? {
        title: "",
        description: [
          { url: PRIVACY_POLICY, label: t("privacy_policy") },
          { url: TERMS_AND_CONDITION, label: t("terms_and_condition") },
          { url: COOKIE_POLICY, label: t("cookie_policy") },
        ],
      }
    : {
        title: "",
        description: [
          { url: SIGN_IN, label: t("log_in") },
          { url: SIGN_UP, label: t("sing_up") },
          { url: PRIVACY_POLICY, label: t("privacy_policy") },
          { url: TERMS_AND_CONDITION, label: t("terms_and_condition") },
          { url: COOKIE_POLICY, label: t("cookie_policy") },
        ],
      };

  const socialLinks = {
    title: t("explore"),
    description: [
      { url: FACEBOOK, label: t("facebook"), external: true },
      // { url: TWITTER, label: t("twitter"), external: true },
      // { url: INSTAGRAM, label: t("instagram"), external: true },
      // { url: PINTEREST, label: t("pinterest"), external: true },
    ],
  };

  if (authLinks) {
    footers.push(authLinks);
  }

  footers.push(socialLinks);

  return (
    <Fragment>
      <Container maxWidth={false} component="footer" className={classes.footer}>
        <Container maxWidth="md">
          <Grid container spacing={4} justify="space-evenly">
            {footers.map((footer, index) => (
              <Grid item xs={6} sm={3} key={index}>
                {footer.url ? (
                  <Typography variant="subtitle1" color="inherit" gutterBottom>
                    <NextLink href={footer.url}>
                      <Link
                        title={footer.title}
                        href={`${HOST}${footer.url}`}
                        variant="subtitle1"
                        color="inherit"
                      >
                        {footer.title}
                      </Link>
                    </NextLink>
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" color="inherit" gutterBottom>
                    {footer.title}
                  </Typography>
                )}

                <ul>
                  {footer.description.map((item) => (
                    <li key={item.label}>
                      {item.external ? (
                        <Link
                          rel="nofollow"
                          href={item.url}
                          title={item.label}
                          target="_blank"
                          variant="body1"
                          color="inherit"
                        >
                          {item.label}
                        </Link>
                      ) : (
                        <NextLink href={item.url} asHref={item.url}>
                          <Link
                            title={item.label}
                            href={`${HOST}${item.url}`}
                            variant="body1"
                            color="inherit"
                          >
                            {item.label}
                          </Link>
                        </NextLink>
                      )}
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Box mt={3} mb={3}>
            <Typography variant="body2" color="inherit">
              {"Contact us "}
              <Link color="inherit" href="mailto:admin@propertyglassdoor.com">
                admin@propertyglassdoor.com
              </Link>
            </Typography>
          </Box>
        </Container>
      </Container>
      <Container maxWidth="md">
        <Box mt={3} mb={3}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="/">
              PropertyGlassdoor
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>

          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="body2"
            color="inherit"
            align="center"
          >
            Made with <FavoriteIcon color="primary" /> &amp; some logic
          </Typography>
        </Box>
      </Container>
    </Fragment>
  );
}
