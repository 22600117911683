import React, { Fragment, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { useRouter } from "next/router";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Collapse from "@material-ui/core/Collapse";
import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import useTranslation from "next-translate/useTranslation";

import PostAddTwoToneIcon from "@material-ui/icons/PostAddTwoTone";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import { useDispatch } from "react-redux";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import useUsersStatus from "./../../states/users";
import {
  SIGN_IN,
  SIGN_UP,
  CREATE_LISTING,
  AGENT_DASHBOARD,
  CONDO_FOR_BUY_PAGE_ROUTE,
  CONDO_FOR_RENT_PAGE_ROUTE,
  HDB_FOR_BUY_PAGE_ROUTE,
  HDB_FOR_RENT_PAGE_ROUTE,
  ROOM_FOR_RENT_PAGE_ROUTE,
  VIEW_PROFILE,
  HOME_ROUTE,
  CONDO_ROUTE,
} from "../../config/routes";
import { mainListItems, secondaryListItems } from "./listItems";
import { doLogout } from "../../../store/actions/Users";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

export default function MenuDrawer({ user, showImage }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBuyMenu, setOpenBuyMenu] = useState(true);
  const [openRentMenu, setOpenRentMenu] = useState(true);
  const { t } = useTranslation("common");
  const isLoggesIn = useUsersStatus();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();

  const moveTo = (routePath) => {
    router.push(routePath);
  };

  const singUpLinks = [
    {
      label: t("post_listing"),
      route: CREATE_LISTING,
      Icon: <PostAddTwoToneIcon />,
    },
  ];
  if (!isLoggesIn) {
    singUpLinks.push({
      label: t("log_in"),
      route: SIGN_IN,
      Icon: <LockOpenTwoToneIcon />,
    });
    singUpLinks.push({
      label: t("sing_up"),
      route: SIGN_UP,
      Icon: <VpnKeyTwoToneIcon />,
    });
  } else if (user.isAgent) {
    singUpLinks.push({
      label: t("dashboard"),
      route: AGENT_DASHBOARD,
      Icon: <DashboardTwoToneIcon />,
    });
  }

  const handleDrawerClose = () => {
    setOpenBuyMenu(false);
  };

  const handleBuyClick = (e) => {
    e.stopPropagation();
    setOpenBuyMenu(!openBuyMenu);
  };

  const handleRentClick = (e) => {
    e.stopPropagation();
    setOpenRentMenu(!openRentMenu);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };

  const openDrawerFn = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(true);
  };

  const closeDrawerFn = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(false);
  };

  const ListComponent = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        {isLoggesIn && user.isAgent ? (
          <ListItem
            onClick={() => {
              moveTo(VIEW_PROFILE.replace("[name]", user.username));
            }}
          >
            <ListItemAvatar>
              {/* <Typography variant="body">{user.name} &nbsp;</Typography> */}
              <Avatar alt={user.name} src={user.picture} />
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={t("view_profile")} />
          </ListItem>
        ) : null}
        {singUpLinks.map(({ label, route, Icon }, index) => (
          <ListItem
            button
            key={label}
            onClick={() => {
              moveTo(route);
            }}
          >
            <ListItemIcon>{Icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      {isLoggesIn ? (
        <Fragment>
          <Divider />
          {mainListItems({ handleDrawerClose, isDesktop })}
          <Divider />
          {secondaryListItems()}
        </Fragment>
      ) : null}
      <Divider />
      <List>
        <ListItem
          onClick={() => {
            moveTo(CONDO_ROUTE("singapore"));
          }}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={t("condos_singapore")} />
        </ListItem>
      </List>
      <List>
        <ListItem button onClick={handleBuyClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={t("buy")} />
          {openBuyMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openBuyMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              {
                label: t("condo_for_sale"),
                route: `/singapore${CONDO_FOR_BUY_PAGE_ROUTE}`,
              },
              {
                label: t("hdb_for_sale"),
                route: `/singapore${HDB_FOR_BUY_PAGE_ROUTE}`,
              },
            ].map((item) => (
              <ListItem
                key={item.label}
                onClick={() => {
                  moveTo(item.route);
                }}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
      <List>
        <ListItem button onClick={handleRentClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={t("rent")} />
          {openRentMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openRentMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              {
                label: t("condo_for_rent"),
                route: `/singapore${CONDO_FOR_RENT_PAGE_ROUTE}`,
              },
              {
                label: t("hdb_for_rent"),
                route: `/singapore${HDB_FOR_RENT_PAGE_ROUTE}`,
              },
              {
                label: t("rooms_for_rent"),
                route: `/singapore${ROOM_FOR_RENT_PAGE_ROUTE}`,
              },
            ].map((item) => (
              <ListItem
                key={item.label}
                onClick={() => {
                  moveTo(item.route);
                }}
                button
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
      <List>
        {isLoggesIn ? (
          <ListItem
            button
            onClick={() => {
              dispatch(doLogout());
              moveTo(HOME_ROUTE);
            }}
          >
            <ListItemIcon>
              <LockTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={t("logout")} />
          </ListItem>
        ) : null}
      </List>
    </div>
  );

  return (
    <Fragment>
      <MenuIcon onClick={openDrawerFn} />
      <Drawer
        anchor={isDesktop ? "right" : "left"}
        open={openDrawer}
        onClose={closeDrawerFn}
      >
        {<ListComponent />}
      </Drawer>
    </Fragment>
  );
}
