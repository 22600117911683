import {
  GET_SOCIAL_PAGE,
  GET_SOCIAL_PAGE_SUCCESS,
  CREATE_SOCIAL_PAGE,
  CREATE_SOCIAL_PAGE_SUCCESS,
  CREATE_SOCIAL_PAGE_ERROR,
  GET_SOCIAL_PAGE_BY_NAME,
  GET_SOCIAL_PAGE_BY_NAME_SUCCESS,
} from "../constants";

export const getSocialPage = (data) => ({
  type: GET_SOCIAL_PAGE,
  data,
});

export const getSocialPageSuccess = (data) => ({
  type: GET_SOCIAL_PAGE_SUCCESS,
  data,
});

export const getSocialPageContentByName = (userId) => ({
  type: GET_SOCIAL_PAGE_BY_NAME,
  userId,
});

export const getSocialPageContentByNameSuccess = (data) => ({
  type: GET_SOCIAL_PAGE_BY_NAME_SUCCESS,
  data,
});

export const createSocialPage = (data) => ({
  type: CREATE_SOCIAL_PAGE,
  data,
});

export const createSocialPageSuccess = (data) => ({
  type: CREATE_SOCIAL_PAGE_SUCCESS,
  data,
});

export const createSocialPageError = (message) => ({
  type: CREATE_SOCIAL_PAGE_ERROR,
  message,
});
