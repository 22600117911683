import {
  GET_AGENCY,
  GET_AGENCY_SUCCESS,
  GET_SELECTED_AGENCY_SUCCESS,
  GET_AGENCY_ERROR,
  GET_SELECTED_AGENCY,
} from "../constants";

export const getAgency = (options) => ({
  type: GET_AGENCY,
  options,
});

export const getAgencySuccess = (data) => ({
  type: GET_AGENCY_SUCCESS,
  data,
});

export const getSelectedAgencySuccess = (data) => ({
  type: GET_SELECTED_AGENCY_SUCCESS,
  data,
});

export const getAgencyError = (message) => ({
  type: GET_AGENCY_ERROR,
  message,
});

export const getSelectedAgency = (ids) => ({
  type: GET_SELECTED_AGENCY,
  ids,
});
