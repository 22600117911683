import { put, takeEvery, call, all } from "redux-saga/effects";
import {
  LOGIN,
  REGISTER,
  USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../constants";
import {
  loginSuccess,
  loginError,
  registerError,
  registerSuccess,
  getUserProfileSuccess,
  saveUserProfileSuccess,
} from "./../actions/Users";
import Fetch from "../../libs/fetch";
import { LOGIN_URL, REGISTER_URL, USER_PROFILE_URL, USER_DETAIL } from "../api";

export function* doLogin(options) {
  try {
    const response = yield call(Fetch, {
      url: LOGIN_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(options.data),
    });
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(loginError(error));
  }
}

export function* updateUserProfile(options) {
  try {
    const response = yield call(Fetch, {
      url: USER_DETAIL,
      method: "PUT",
      headers: null,
      body: JSON.stringify(options.data),
    });
    yield put(saveUserProfileSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* getUserProfile(options) {
  try {
    const response = yield call(Fetch, {
      url: USER_PROFILE_URL,
      method: "GET",
      headers: null,
      queryParams: options.data || {},
    });
    yield put(getUserProfileSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* doRegister(options) {
  try {
    const response = yield call(Fetch, {
      url: REGISTER_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(options.data),
    });

    if (response.status > 300) {
      throw response.message;
    } else {
      yield put(registerSuccess(response));
    }
  } catch (error) {
    yield put(registerError(error));
  }
}

export default function* propertySaga() {
  yield takeEvery(LOGIN, doLogin);
  yield takeEvery(REGISTER, doRegister);
  yield takeEvery(USER_PROFILE, getUserProfile);
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
}
