
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { DefaultSeo } from "next-seo";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import NextNprogress from "nextjs-progressbar";
import useTranslation from "next-translate/useTranslation";
import theme from "../src/theme";
import Header from "./../src/components/Header";
import Footer from "./../src/components/Footer";
import AgentHeader from "./../src/components/AgentHeader";
import Container from "@material-ui/core/Container";
import "react-slideshow-image/dist/styles.css";
import "react-bnb-gallery/dist/style.css";
import { StoreProvider } from "./../store";
import { init } from "./../libs/sentry";
import globalStyles from "./../src/styles/global";
import {
  languageAlternates,
  facebook,
  additionalLinkTags,
  additionalMetaTags,
  title,
  description,
  twitter,
  keywords,
} from "../src/config/seo";

init();

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  consumerContainer: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "65px",
      marginTop: theme.spacing(2),
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedContainer: {
    [theme.breakpoints.up("md")]: {
      top: "65px",
      height: "calc(100% + 165px)",
      position: "relative",
      paddingBottom: theme.spacing(2),
    },
  },
}));

function MyApp(props) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const {
    Component,
    pageProps,
    router: { query, pathname },
  } = props;

  if (!query.city) {
    query.city = null;
  } else {
    query.city = query.city.toLowerCase();
  }

  const isAgentRoute = pathname.match(/\/agent\//);
  const isSocialRoute = pathname.match(/\/social\//);
  const hideHeader = !isAgentRoute && isSocialRoute;
  const hideFooter =
    isSocialRoute || isAgentRoute || (pageProps && pageProps.hideFooter);

  globalStyles();

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const pathName =
    props.router && props.router.pathname
      ? props.router.pathname.replace(/\//gi, "")
      : "";
  const titleText = title[pathName] || title.home;
  const descriptionText = description[pathName] || description.home;
  const keywordText = keywords[pathName] || keywords.home;

  return (
    <React.Fragment>
      <StoreProvider>
        <Head>
          <title>{t(titleText)}</title>
          <DefaultSeo
            title={t(titleText)}
            description={t(descriptionText)}
            languageAlternates={languageAlternates}
            facebook={facebook}
            additionalLinkTags={additionalLinkTags}
            additionalMetaTags={additionalMetaTags}
            // twitter={twitter}
          />
          <meta name="keywords" content={keywordText} />
        </Head>

        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {/* {isAgentRoute ? (
            <AgentPageWrapper>
              <Component {...pageProps} {...query} />
            </AgentPageWrapper>
          ) : ( */}
          <NextNprogress
            color="#29D"
            startPosition={0.3}
            stopDelayMs={200}
            height={2}
            showOnShallow={true}
          />
          <Fragment>
            <GoogleOAuthProvider clientId="1088770577533-80c5lsb2prsked1ijthq1n8eu1b11gr9.apps.googleusercontent.com">
              {hideHeader ? null : <Header {...query} />}
              <div className={classes.fixedContainer}>
                {isAgentRoute ? (
                  <Container
                    disableGutters={true}
                    maxWidth="md"
                    className={classes.container}
                  >
                    <Component {...pageProps} {...query} />
                  </Container>
                ) : (
                  <Component {...pageProps} {...query} />
                )}
              </div>
              {hideFooter ? null : <Footer />}
            </GoogleOAuthProvider>
          </Fragment>
          {/* )} */}
        </ThemeProvider>
      </StoreProvider>
    </React.Fragment>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  