import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import StarBorder from "@material-ui/icons/StarBorder";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import useTranslation from "next-translate/useTranslation";
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';

import PostAddTwoToneIcon from '@material-ui/icons/PostAddTwoTone';
import {
  CREATE_LISTING,
  LISTING,
  AGENT_DASHBOARD,
  HOME_ROUTE,
  LEADS,
  INSTAGRAM_PAGE,
  PROPERTY,
  CREATE_NEW_PROJECT,
  PROPERTIES,
  EDIT_PROFILE,
} from "./../../config/routes";
import { useRouter } from "next/router";

export const mainListItems = ({ handleDrawerClose, isDesktop }) => {
  const router = useRouter();
  const { t } = useTranslation("common");
  function navigate(pathName) {
    if (!isDesktop) {
      handleDrawerClose();
    }

    router.push({
      pathname: pathName,
    });
  }

  return (
    <List>
      <ListItem button>
        <ListItemIcon>
          <ListAltTwoToneIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('your_listings')}
          onClick={() => {
            navigate(LISTING);
          }}
        />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <PostAddTwoToneIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('create_new_listing')}
          onClick={() => {
            navigate(CREATE_LISTING);
          }}
        />
      </ListItem>
      {/* <ListItem button>
        <ListItemIcon>
          <StarBorder />
        </ListItemIcon>
        <ListItemText
          primary={t('create_new_project')}
          onClick={() => {
            navigate(CREATE_NEW_PROJECT);
          }}
        />
      </ListItem> */}
      <ListItem button>
        <ListItemIcon>
          <AccountTreeTwoToneIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('social_page')}
          onClick={() => {
            navigate(INSTAGRAM_PAGE);
          }}
        />
      </ListItem>
      {/* <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('properties')}
          onClick={() => {
            navigate(PROPERTIES);
          }}
        />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('create_property')}
          onClick={() => {
            navigate(PROPERTY);
          }}
        />
      </ListItem> */}
    </List>
  );
};

export const secondaryListItems = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  function navigate(pathName) {
    router.push({
      pathname: pathName,
    });
  }

  return (
    <List>
      <ListSubheader inset>{t('profile')}</ListSubheader>
      <ListItem button>
        <ListItemIcon>
          <AccountBoxTwoToneIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('edit_profile')}
          onClick={() => {
            navigate(EDIT_PROFILE);
          }}
        />
      </ListItem>
    </List>
  );
};
