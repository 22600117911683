import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import StarBorder from "@material-ui/icons/StarBorder";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import {
  CREATE_LISTING,
  LISTING,
  AGENT_DASHBOARD,
  HOME_ROUTE,
  LEADS,
  INSTAGRAM_PAGE,
  PROPERTY,
  CREATE_NEW_PROJECT,
  PROPERTIES,
} from "./../../config/routes";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const mainListItems = ({handleDrawerClose, isDesktop}) => {
  const router = useRouter();
  const classes = useStyles();
  const [openListingTab, setOpenListingTab] = React.useState(true);

  const handlePpenListingTabClick = () => {
    setOpenListingTab(!openListingTab);
  };

  function navigate(pathName) {
    if (!isDesktop) {
      handleDrawerClose();
    }

    router.push({
      pathname: pathName,
    });
  }

  return (
    <div>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          primary="Home"
          onClick={() => {
            navigate(HOME_ROUTE);
          }}
        />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          onClick={() => {
            navigate(AGENT_DASHBOARD);
          }}
        />
      </ListItem>
      <ListItem button onClick={handlePpenListingTabClick}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Listings" />
        {openListingTab ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openListingTab} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Listings"
              onClick={() => {
                navigate(LISTING);
              }}
            />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Create Listing"
              onClick={() => {
                navigate(CREATE_LISTING);
              }}
            />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Create New Project"
              onClick={() => {
                navigate(CREATE_NEW_PROJECT);
              }}
            />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText
          primary="Social Page"
          onClick={() => {
            navigate(INSTAGRAM_PAGE);
          }}
        />
      </ListItem>
       <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText
          primary="Properties"
          onClick={() => {
            navigate(PROPERTIES);
          }}
        />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText
          primary="Create Property"
          onClick={() => {
            navigate(PROPERTY);
          }}
        />
      </ListItem>
    </div>
  );
};

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Profile</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Edit Profile" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItem>
  </div>
);
