import {
  GET_DEVELOPER,
  GET_DEVELOPER_SUCCESS,
  GET_SELECTED_DEVELOPER_SUCCESS,
  GET_DEVELOPER_ERROR,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  selectedSuggestions: [],
  suggestions: [],
  errorMessage: null,
};

// Reducers (Modifies The State And Returns A New State)
const DeveloperReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVELOPER:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_DEVELOPER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suggestions: action.data,
      };
    case GET_SELECTED_DEVELOPER_SUCCESS:
      return {
        ...state,
        selectedSuggestions: action.data,
      };
    case GET_DEVELOPER_ERROR:
      return {
        ...state,
        isLoading: false,
        suggestions: [],
        errorMessage: action.message,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default DeveloperReducer;
