import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FF8B45", //'#19857b',
    },
    secondary: {
      main: "#19857b", //'#e03d31', //##007ABD
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontFamily: "Nunito",
    },
    h2: {
      fontFamily: "Nunito",
    },
    h3: {
      fontFamily: "Nunito",
    },
    h4: {
      fontFamily: "Nunito",
    },
    h5: {
      fontFamily: "Nunito",
    },
    h6: {
      fontFamily: "Nunito",
    },
    subtitle1: {
      fontSize: 15,
    },
    body1: {
      fontSize: 14,
    },
  },
});

export default theme;
