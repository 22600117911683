import { combineReducers } from "redux";

import UsersReducer from "./Users";
import ListingReducer from "./Listing";
import SocialReducer from "./Social";
import PropertyReducer from "./Property";
import POIReducer from "./POI";
import LeadsReducer from "./Leads";
import DeveloperReducer from "./Developer";
import AgencyReducer from "./Agency";
import ReviewReducer from "./Review";

export const reducers = combineReducers({
  UsersReducer,
  ListingReducer,
  SocialReducer,
  PropertyReducer,
  POIReducer,
  LeadsReducer,
  DeveloperReducer,
  AgencyReducer,
  ReviewReducer,
});
