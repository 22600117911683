import {
  CREATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY,
  CREATE_PROPERTY,
  UPDATE_PROPERTY_SUCCESS,
  GET_PROPERTIES,
  UPLOAD_PROPERTY_MEDIA,
  UPLOAD_PROPERTY_MEDIA_SUCCESS,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY,
  GET_SELECTED_PROPERTIES_SUCCESS,
  CREATE_PROPERTY_UNIT_SUCCESS,
  CREATE_PROPERTY_BLOCK_SUCCESS,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  createdSucess: false,
  propertyData: {},
  properties: [],
  totalRecords: 0,
  propertyDetail: null,
  selectedProperties: [],
};

// Reducers (Modifies The State And Returns A New State)
const PropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case CREATE_PROPERTY:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case UPDATE_PROPERTY:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        propertyData: action.data,
        createdSucess: true,
      };
    case UPDATE_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdSucess: true,
      };
    case UPLOAD_PROPERTY_MEDIA:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case UPLOAD_PROPERTY_MEDIA_SUCCESS:
      let mediaArr = [];
      if (state.propertyData.media) {
        mediaArr = [...mediaArr, ...state.propertyData.media];
      }

      if (action.data.images) {
        mediaArr = [...mediaArr, ...action.data.images];
      }
      return {
        ...state,
        isLoading: false,
        propertyDetail: {
          ...state.propertyDetail,
          media: mediaArr,
          youtubeUrls: action.data.youtubeUrls,
          virtualUrl: action.data.virtualUrl,
        },
      };
    case GET_PROPERTIES:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        propertyData: action.data || {},
        propertyDetail: action.data || {},
      };
    case GET_PROPERTY:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROPERTIES_SUCCESS:
      console.log(action.data, "action.data");
      return {
        ...state,
        totalRecords: action.data.totalRecords || 0,
        properties: action.data.properties || [],
        isLoading: false,
      };
    case GET_SELECTED_PROPERTIES_SUCCESS:
      const properties =
        action.data && action.data.properties
          ? action.data.properties
          : action.data;
      return {
        ...state,
        selectedProperties: properties || [],
      };
    case CREATE_PROPERTY_UNIT_SUCCESS:
      const units = state.propertyData.units || [];
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          units: [...units, action.data],
        },
        propertyDetail: {
          ...state.propertyDetail,
          units: [...units, action.data],
        },
      };
    case CREATE_PROPERTY_BLOCK_SUCCESS:
      const blocks = state.propertyData.blocks || [];
      const newBlocks = blocks.map((block) => {
        if (block.name === action.data.name) {
          return action.data;
        }

        return block;
      });

      if (!newBlocks.length) {
        newBlocks.push(action.data);
      }

      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          blocks: newBlocks,
        },
        propertyDetail: {
          ...state.propertyDetail,
          blocks: newBlocks,
        },
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default PropertyReducer;
