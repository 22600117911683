import { put, takeLatest, call, all } from "redux-saga/effects";
import { SAVE_REVIEW, GET_REVIEWS } from "../constants";
import { getReviewsError, getReviewsSuccess, saveReviewSuccess } from "./../actions/Reviews";
import Fetch from "../../libs/fetch";
import { REVIEW_PAGE_URL } from "../api";

export function* createReview({ data, ...others }) {
  try {
    let response = yield call(Fetch, {
      url: REVIEW_PAGE_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(data),
    });
    yield put(saveReviewSuccess(response));
  } catch (error) {
    yield put(getReviewsError(error));
  }
}

export function* getReviews(options) {
  try {
    const response = yield call(Fetch, {
      url: REVIEW_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: options.data,
    });
    yield put(getReviewsSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export default function* LeadsSaga() {
  yield takeLatest(SAVE_REVIEW, createReview);
  yield takeLatest(GET_REVIEWS, getReviews);
}
