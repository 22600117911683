import { put, takeLatest, call, all } from "redux-saga/effects";
import { GET_AGENCY, GET_SELECTED_AGENCY } from "../constants";
import {
  getAgencySuccess,
  getAgencyError,
  getSelectedAgencySuccess,
} from "../actions/Agency";
import Fetch from "../../libs/fetch";
import { AGENCY_PAGE_URL } from "../api";

export function* getDevelopers({ options }) {
  try {
    let response = yield call(Fetch, {
      url: AGENCY_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: options,
    });
    yield put(getAgencySuccess(response));
  } catch (error) {
    yield put(getAgencyError(error));
  }
}

export function* getSelectedDevelopers({ ids }) {
  try {
    let response = yield call(Fetch, {
      url: AGENCY_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: { ids },
    });

    yield put(getAgencyError(response));
  } catch (error) {
    yield put(getPOIError(error));
  }
}

export default function* DeveloperSaga() {
  yield takeLatest(GET_AGENCY, getDevelopers);
  yield takeLatest(GET_SELECTED_AGENCY, getSelectedDevelopers);
}
