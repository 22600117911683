import { put, takeLatest, call, all } from "redux-saga/effects";
import { CREATE_LEAD, GET_LEADS } from "../constants";
import { generateLeadSuccess, generateLeadError, getLeadsSuccess } from "./../actions/Leads";
import Fetch from "../../libs/fetch";
import { LEADS_PAGE_URL } from "../api";

export function* createLeads({ data, ...others }) {
  try {
    console.log(data, others);
    let response = yield call(Fetch, {
      url: LEADS_PAGE_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(data),
    });
    yield put(generateLeadSuccess(response));
  } catch (error) {
    yield put(generateLeadError(error));
  }
}

export function* getLeads(options) {
  try {
    const response = yield call(Fetch, {
      url: LEADS_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: options.data,
    });
    yield put(getLeadsSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export default function* LeadsSaga() {
  yield takeLatest(CREATE_LEAD, createLeads);
  yield takeLatest(GET_LEADS, getLeads);
}
