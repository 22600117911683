import {
  GET_DEVELOPER,
  GET_DEVELOPER_SUCCESS,
  GET_SELECTED_DEVELOPER_SUCCESS,
  GET_DEVELOPER_ERROR,
  GET_SELECTED_DEVELOPER,
} from "../constants";

export const getDeveloper = (options) => ({
  type: GET_DEVELOPER,
  options,
});

export const getDeveloperSuccess = (data) => ({
  type: GET_DEVELOPER_SUCCESS,
  data,
});

export const getSelectedDeveloperSuccess = (data) => ({
  type: GET_SELECTED_DEVELOPER_SUCCESS,
  data,
});

export const getDeveloperError = (message) => ({
  type: GET_DEVELOPER_ERROR,
  message,
});

export const getSelectedDeveloper = (ids) => ({
  type: GET_SELECTED_DEVELOPER,
  ids,
});
