import {
  GET_SOCIAL_PAGE,
  GET_SOCIAL_PAGE_SUCCESS,
  CREATE_SOCIAL_PAGE,
  CREATE_SOCIAL_PAGE_SUCCESS,
  CREATE_SOCIAL_PAGE_ERROR,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  name: "",
  userId: null,
  content: [],
  errorMessage: null,
};

// Reducers (Modifies The State And Returns A New State)
const SocialPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOCIAL_PAGE:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_SOCIAL_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        name: action.data.name,
        content: JSON.parse(action.data.content),
        userId: action.data.userId,
        errorMessage: null,
      };
    case CREATE_SOCIAL_PAGE:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case CREATE_SOCIAL_PAGE_SUCCESS:
      console.log(action, "action");
      return {
        ...state,
        isLoading: false,
        name: action.data.name,
        content: JSON.parse(action.data.content),
        userId: action.data.userId,
      };
    case CREATE_SOCIAL_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: true,
      };
    default:
      return { ...state };
  }
};

// Exports
export default SocialPageReducer;
