const isProd = process.env.NODE_ENV === "production";
export const HOST = isProd
  ? `https://propertyglassdoor.com`
  : "http://localhost:3000";
export const FORGOT_PASSWORD = "/forgot-password";
export const SIGN_UP = "/register";
export const SIGN_IN = "/login";
export const HOME_ROUTE = "/";
export const FOR_BUY_PAGE_ROUTE = "/for-sale";
export const FOR_RENT_PAGE_ROUTE = "/for-rent";
export const CONDO_FOR_BUY_PAGE_ROUTE = "/condo-for-sale";
export const CONDO_FOR_RENT_PAGE_ROUTE = "/condo-for-rent";
export const HDB_FOR_BUY_PAGE_ROUTE = "/hdb-for-sale";
export const HDB_FOR_RENT_PAGE_ROUTE = "/hdb-for-rent";
export const ROOM_FOR_RENT_PAGE_ROUTE = "/rooms-for-rent";
export const SEARCH_PAGE_ROUTE = "/search";
export const UNDER_CONSTRUCTION_PROPERTY = "/under-construction-property";
export const LISTING_DETAIL = (city, slug) => `/${city}/listing/${slug}`;
export const CONDO_ROUTE = (city) => `/${city}/condos`;
export const PROPERTY_SEARCH_PAGE = (city) => `/${city}/properties`;
export const getPostPropertyForFreeUrl = (city) =>
  `/${city}/post-property-for-free`;

export const CONTACT_US = "/contact-us";
export const ABOUT_US = "/about-us";
export const PRIVACY_POLICY = `${HOST}/privacy-policy`;
export const TERMS_OF_USE = `${HOST}/terms-of-use`;
export const TERMS_AND_CONDITION = `${HOST}/terms-and-condition`;
export const COOKIE_POLICY = `${HOST}/cookie-policy`;

// Agent Routes
export const AGENT_PATH = "/agent";
export const AGENT_DASHBOARD = `${AGENT_PATH}/dashboard`;
export const CREATE_LISTING = `${AGENT_PATH}/create-listing`;
export const CREATE_NEW_PROJECT = `${AGENT_PATH}/create-new-project`;
export const CREATE_PROPERTY = `${AGENT_PATH}/create-property`;
export const LISTING = `${AGENT_PATH}/listings`;
export const INSTAGRAM_PAGE = `${AGENT_PATH}/social/page`;
export const LEADS = `${AGENT_PATH}/leads`;
export const PROPERTY = `${AGENT_PATH}/property`;
export const PROPERTIES = `${AGENT_PATH}/properties`;
export const EDIT_PROFILE = `${AGENT_PATH}/profile`;
export const VIEW_PROFILE = `${AGENT_PATH}/[name]`;

export const SOCAIL_PUBLIC_PAGE_URL = `${HOST}/social/`;

// Social Media

export const FACEBOOK = "https://www.facebook.com/propertyGlassdoor/";
export const TWITTER = "https://twitter.com";
export const INSTAGRAM = "https://instagram.com";
export const PINTEREST = "https://pintrest.com";

export const getPropertyForSaleUrl = (city, slug, type, id) =>
  `/${city}/${type}/${slug}/property-for-sale/${id}`;
export const getPropertyForRentUrl = (city, slug, type, id) =>
  `/${city}/${type}/${slug}/property-for-rent/${id}`;
export const getListingDetail = (city, slug) => `/${city}/listing/${slug}`;
export const getListingDetailPath = (city, slug) => `/[city]/listing/[slug]`;
export const getPropertyDetail = (city, slug, type) =>
  `/${city}/${type.toLowerCase()}/${slug}`;
export const getPropertyDetailPath = `/[city]/[type]/[slug]`;
export const getPropertyForSalePath = () =>
  `/[city]/[type]/[slug]/property-for-sale/[id]`;
export const getPropertyForRentPath = () =>
  `/[city]/[type]/[slug]/property-for-rent/[id]`;

export const getUserProfileView = (username) => `${AGENT_PATH}/${username}`;
