import {
  CREATE_LISTING_SUCCESS,
  UPDATE_LISTING,
  CREATE_LISTING,
  UPDATE_LISTING_SUCCESS,
  GET_LISTINGS,
  UPDATE_LISTING_REDUCER,
  UPLOAD_LISTING_MEDIA,
  UPLOAD_LISTING_MEDIA_SUCCESS,
  GET_LISTINGS_SUCCESS,
  GET_LISTING_SUCCESS,
  GET_LISTING,
  MAKE_FAVOURITE,
  REMOVE_FAVOURITE,
  UPDATE_UNIT_LISTING_REDUCER,
  CREATE_UNIT_LISTING_REDUCER,
  CREATE_UNIT_LISTING_SUCCESS,
  UPDATE_UNIT_LISTING_SUCCESS,
  GET_UNIT_LISTINGS_SUCCESS,
  UPLOAD_LISTING_DATA_MEDIA,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  createdSucess: false,
  listingData: {},
  propertyData: {},
  listings: [],
  unitType: [],
  totalRecords: 0,
  listingDetail: null,
  favourites: [],
  loadingUnit: false,
};

// Reducers (Modifies The State And Returns A New State)
const ListingReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case CREATE_LISTING:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case UPDATE_UNIT_LISTING_REDUCER:
    case CREATE_UNIT_LISTING_REDUCER:
      return {
        ...state,
        loadingUnit: true,
      };
    case CREATE_UNIT_LISTING_SUCCESS:
      return {
        ...state,
        loadingUnit: false,
      };
    case UPDATE_UNIT_LISTING_SUCCESS:
      return {
        ...state,
        loadingUnit: false,
      };
    case MAKE_FAVOURITE:
      return {
        ...state,
        favourites: [...state.favourites, action.id],
      };
    case REMOVE_FAVOURITE:
      return {
        ...state,
        favourites: state.favourites.filter(
          (favourite) => favourite !== action.id
        ),
      };
    case UPDATE_LISTING:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listingData: action.data.listingData,
        createdSucess: true,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdSucess: true,
        listingData: {
          ...state.listingData,
          ...action.data.listingData,
        },
      };
    case UPLOAD_LISTING_MEDIA:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case UPLOAD_LISTING_DATA_MEDIA:
      return {
        ...state,
        listingData: {
          ...state.listingData,
          ...action.data,
        },
      };
    case UPLOAD_LISTING_MEDIA_SUCCESS:
      let mediaArr = [];
      if (state.listingData.media) {
        mediaArr = [...mediaArr, ...state.listingData.media];
      }

      if (action.data.mediaList) {
        mediaArr = [...mediaArr, ...action.data.mediaList];
      }
      return {
        ...state,
        createdSucess: true,
        isLoading: false,
        listingData: {
          ...state.listingData,
          media: mediaArr,
          youtubeUrls: action.data.youtubeUrls,
          virtualUrl: action.data.virtualUrl,
        },
      };
    case UPDATE_LISTING_REDUCER:
      return {
        ...state,
        ...action.data,
      };
    case GET_LISTINGS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listingDetail: action.data,
      };
    case GET_UNIT_LISTINGS_SUCCESS:
      return {
        ...state,
        unitType: action.data.listings || [],
        isLoading: false,
      };
    case GET_LISTING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LISTINGS_SUCCESS:
      return {
        ...state,
        totalRecords: action.data.totalRecords || 0,
        listings: action.data.listings || [],
        isLoading: false,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default ListingReducer;
