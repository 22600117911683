import {
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_ERROR,
  SAVE_REVIEW,
  SAVE_REVIEW_SUCCESS,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  reviews: [],
  successMessage: null,
  errorMessage: null,
};

const ReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case SAVE_REVIEW:
      return {
        ...state,
        isLoading: true,
        successMessage: null,
        errorMessage: null,
      };
    case SAVE_REVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: "REVIEW_CREATED_SUCCESSFULLY",
        errorMessage: null,
      };
    case GET_REVIEWS_ERROR:
      return {
        ...state,
        isLoading: false,
        successMessage: null,
        errorMessage: action.data,
      };
    case GET_REVIEWS:
      return {
        ...state,
        isLoading: true,
        successMessage: null,
        errorMessage: null,
      };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reviews: action.data,
        successMessage: null,
        errorMessage: null,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default ReviewReducer;
