import {
  CREATE_LISTING,
  UPDATE_LISTING,
  GET_LISTING,
  CREATE_LISTING_SUCCESS,
  UPDATE_LISTING_SUCCESS,
  GET_LISTING_SUCCESS,
  GET_LISTINGS,
  GET_LISTINGS_SUCCESS,
  UPDATE_LISTING_REDUCER,
  UPLOAD_LISTING_MEDIA,
  UPLOAD_LISTING_MEDIA_SUCCESS,
  MAKE_FAVOURITE,
  REMOVE_FAVOURITE,
  UPDATE_UNIT_LISTING_REDUCER,
  CREATE_UNIT_LISTING_REDUCER,
  CREATE_UNIT_LISTING_SUCCESS,
  UPDATE_UNIT_LISTING_SUCCESS,
  GET_UNIT_LISTINGS_SUCCESS,
  GET_UNIT_LISTINGS,
  UPLOAD_LISTING_DATA_MEDIA,
} from "../constants";

export const makeFavourite = (id) => ({
  type: MAKE_FAVOURITE,
  id,
});

export const removeFavourite = (id) => ({
  type: REMOVE_FAVOURITE,
  id,
});

export const createListing = (data) => ({
  type: CREATE_LISTING,
  data,
});

export const createListingSuccess = (data) => ({
  type: CREATE_LISTING_SUCCESS,
  data,
});

export const updateListing = (data) => ({
  type: UPDATE_LISTING,
  data,
});

export const updateListingSuccess = (data) => ({
  type: UPDATE_LISTING_SUCCESS,
  data,
});

export const updateListingMediaData = (data) => ({
  type: UPLOAD_LISTING_DATA_MEDIA,
  data,
});

export const getListings = (filter) => ({
  type: GET_LISTINGS,
  filter,
});

export const getUnitListings = (filter) => ({
  type: GET_UNIT_LISTINGS,
  filter,
});

export const getUnitListingsSuccess = (data) => ({
  type: GET_UNIT_LISTINGS_SUCCESS,
  data,
});

export const getListing = (id) => ({
  type: GET_LISTING,
  id,
});

export const getListingsSuccess = (data) => ({
  type: GET_LISTINGS_SUCCESS,
  data,
});

export const getListingSuccess = (data) => ({
  type: GET_LISTING_SUCCESS,
  data,
});

export const updateData = (data) => ({
  type: UPDATE_LISTING_REDUCER,
  data,
});

export const updateUnitListing = (data) => ({
  type: UPDATE_UNIT_LISTING_REDUCER,
  data,
});

export const createUnitListing = (data) => ({
  type: CREATE_UNIT_LISTING_REDUCER,
  data,
});

export const uploadMediaFiles = (data) => ({
  type: UPLOAD_LISTING_MEDIA,
  data: data,
});

export const createUnitListingSuccess = (data) => ({
  type: CREATE_UNIT_LISTING_SUCCESS,
  data,
});

export const updateUnitListingSuccess = (data) => ({
  type: UPDATE_UNIT_LISTING_SUCCESS,
  data,
});

export const uploadMediaSuccess = (data) => ({
  type: UPLOAD_LISTING_MEDIA_SUCCESS,
  data: data,
});
