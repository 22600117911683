import {
  CREATE_PROPERTY,
  UPDATE_PROPERTY,
  GET_PROPERTY,
  CREATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_SUCCESS,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTIES,
  GET_PROPERTIES_SUCCESS,
  UPDATE_PROPERTIES_REDUCER,
  UPLOAD_PROPERTY_MEDIA,
  UPLOAD_PROPERTY_MEDIA_SUCCESS,
  GET_SELECTED_PROPERTIES,
  GET_SELECTED_PROPERTIES_SUCCESS,
  CREATE_PROPERTY_UNIT,
  CREATE_PROPERTY_UNIT_SUCCESS,
  CREATE_PROPERTY_BLOCK,
  CREATE_PROPERTY_BLOCK_SUCCESS,
} from "../constants";

export const createProperty = (data) => ({
  type: CREATE_PROPERTY,
  data,
});

export const createPropertySuccess = (data) => ({
  type: CREATE_PROPERTY_SUCCESS,
  data,
});

export const updateProperty = (data) => ({
  type: UPDATE_PROPERTY,
  data,
});

export const updatePropertySuccess = (data) => ({
  type: UPDATE_PROPERTY_SUCCESS,
  data,
});

export const getProperties = (filter) => ({
  type: GET_PROPERTIES,
  filter,
});

export const getProperty = (id) => ({
  type: GET_PROPERTY,
  id,
});

export const getPropertiesSuccess = (data) => ({
  type: GET_PROPERTIES_SUCCESS,
  data,
});

export const getPropertySuccess = (data) => ({
  type: GET_PROPERTY_SUCCESS,
  data,
});

export const updateData = (data) => ({
  type: UPDATE_PROPERTIES_REDUCER,
  data,
});

export const uploadMediaFiles = (data) => ({
  type: UPLOAD_PROPERTY_MEDIA,
  data: data,
});

export const uploadMediaSuccess = (data) => ({
  type: UPLOAD_PROPERTY_MEDIA_SUCCESS,
  data: data,
});

export const getSelectedProperties = (id) => ({
  type: GET_SELECTED_PROPERTIES,
  id,
});

export const getSelectedPropertiesSuccess = (data) => ({
  type: GET_SELECTED_PROPERTIES_SUCCESS,
  data,
});

export const createPropertyUnit = (data) => ({
  type: CREATE_PROPERTY_UNIT,
  data,
});

export const createPropertyUnitSuccess = (data) => ({
  type: CREATE_PROPERTY_UNIT_SUCCESS,
  data,
});

export const createPropertyBlock = (data) => ({
  type: CREATE_PROPERTY_BLOCK,
  data,
});

export const createPropertyBlockSuccess = (data) => ({
  type: CREATE_PROPERTY_BLOCK_SUCCESS,
  data,
});
