import UsersSaga from "./Users";
import ListingSaga from "./Listing";
import SocialSaga from "./Social";
import PropertySaga from "./Property";
import POISaga from "./POI";
import LeadsSaga from "./Leads";
import DeveloperSaga from "./Developer";
import AgencySaga from "./Agency";
import ReviewSaga from "./Review";

export default [
  UsersSaga,
  ListingSaga,
  SocialSaga,
  PropertySaga,
  POISaga,
  LeadsSaga,
  DeveloperSaga,
  AgencySaga,
  ReviewSaga,
];
