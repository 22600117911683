import { put, takeLatest, call, all } from "redux-saga/effects";
import { GET_DEVELOPER, GET_SELECTED_DEVELOPER } from "../constants";
import {
  getDeveloperSuccess,
  getDeveloperError,
  getSelectedDeveloperSuccess,
} from "../actions/Developer";
import Fetch from "../../libs/fetch";
import { DEVELOPER_PAGE_URL } from "../api";

export function* getDevelopers({ options }) {
  try {
    let response = yield call(Fetch, {
      url: DEVELOPER_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: options,
    });
    yield put(getDeveloperSuccess(response));
  } catch (error) {
    yield put(getDeveloperError(error));
  }
}

export function* getSelectedDevelopers({ ids }) {
  try {
    let response = yield call(Fetch, {
      url: DEVELOPER_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: { ids },
    });

    yield put(getDeveloperError(response));
  } catch (error) {
    yield put(getPOIError(error));
  }
}

export default function* DeveloperSaga() {
  yield takeLatest(GET_DEVELOPER, getDevelopers);
  yield takeLatest(GET_SELECTED_DEVELOPER, getSelectedDevelopers);
}
