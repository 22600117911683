export const CREATE_LISTING = "CREATE_LISTING";
export const UPDATE_LISTING = "UPDATE_LISTING";
export const CREATE_LISTING_SUCCESS = "CREATE_LISTING_SUCCESS";
export const UPDATE_LISTING_SUCCESS = "UPDATE_LISTING_SUCCESS";
export const GET_LISTINGS = "GET_LISTINGS";
export const GET_LISTING = "GET_LISTING";
export const GET_LISTING_SUCCESS = "GET_LISTING_SUCCESS";
export const GET_LISTINGS_SUCCESS = "GET_LISTINGS_SUCCESS";
export const MAKE_FAVOURITE = "MAKE_FAVOURITE";
export const REMOVE_FAVOURITE = "REMOVE_FAVOURITE";
export const USER_PROFILE = "USER_PROFILE";
export const UPDATE_UNIT_LISTING_REDUCER = "UPDATE_UNIT_LISTING_REDUCER";
export const CREATE_UNIT_LISTING_REDUCER = "CREATE_UNIT_LISTING_REDUCER";
export const CREATE_UNIT_LISTING_SUCCESS = "CREATE_UNIT_LISTING_SUCCESS";
export const UPDATE_UNIT_LISTING_SUCCESS = "UPDATE_UNIT_LISTING_SUCCESS";
export const GET_UNIT_LISTINGS = "GET_UNIT_LISTINGS";
export const GET_UNIT_LISTINGS_SUCCESS = "GET_UNIT_LISTINGS_SUCCESS";
export const UPLOAD_LISTING_DATA_MEDIA = "UPLOAD_LISTING_DATA_MEDIA";

export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const UPDATE_LISTING_REDUCER = "UPDATE_LISTING_REDUCER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const LOGOUT = "LOGOUT";

export const UPLOAD_LISTING_MEDIA = "UPLOAD_LISTING_MEDIA";
export const UPLOAD_LISTING_MEDIA_SUCCESS = "UPLOAD_LISTING_MEDIA_SUCCESS";

export const GET_SOCIAL_PAGE = "GET_SOCIAL_PAGE";
export const GET_SOCIAL_PAGE_SUCCESS = "GET_SOCIAL_PAGE_SUCCESS";
export const CREATE_SOCIAL_PAGE = "CREATE_SOCIAL_PAGE";
export const CREATE_SOCIAL_PAGE_SUCCESS = "CREATE_SOCIAL_PAGE_SUCCESS";
export const CREATE_SOCIAL_PAGE_ERROR = "CREATE_SOCIAL_PAGE_ERROR";
export const GET_SOCIAL_PAGE_BY_NAME = "GET_SOCIAL_PAGE_BY_NAME";
export const GET_SOCIAL_PAGE_BY_NAME_SUCCESS =
  "GET_SOCIAL_PAGE_BY_NAME_SUCCESS";

export const CREATE_PROPERTY = "CREATE_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const GET_PROPERTY = "GET_PROPERTY";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_PROPERTIES_SUCCESS = "GET_PROPERTIES_SUCCESS";
export const UPDATE_PROPERTIES_REDUCER = "UPDATE_PROPERTIES_REDUCER";
export const UPLOAD_PROPERTY_MEDIA = "UPLOAD_PROPERTY_MEDIA";
export const UPLOAD_PROPERTY_MEDIA_SUCCESS = "UPLOAD_PROPERTY_MEDIA_SUCCESS";
export const GET_SELECTED_PROPERTIES = "GET_SELECTED_PROPERTIES";
export const GET_SELECTED_PROPERTIES_SUCCESS =
  "GET_SELECTED_PROPERTIES_SUCCESS";
export const CREATE_PROPERTY_UNIT = "CREATE_PROPERTY_UNIT";
export const CREATE_PROPERTY_UNIT_SUCCESS = "CREATE_PROPERTY_UNIT_SUCCESS";
export const CREATE_PROPERTY_BLOCK = "CREATE_PROPERTY_BLOCK";
export const CREATE_PROPERTY_BLOCK_SUCCESS = "CREATE_PROPERTY_BLOCK_SUCCESS";

export const GET_POI = "GET_POI";
export const GET_POI_SUCCESS = "GET_POI_SUCCESS";
export const GET_POI_ERROR = "GET_POI_ERROR";
export const GET_SELECTED_POI = "GET_SELECTED_POI";
export const GET_SELECTED_POI_SUCCESS = "GET_SELECTED_POI_SUCCESS";

export const CREATE_LEAD = "CREATE_LEAD";
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
export const CREATE_LEAD_ERROR = "CREATE_LEAD_ERROR";
export const GET_LEADS = "GET_LEADS";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";

export const GET_DEVELOPER = "GET_DEVELOPER";
export const GET_DEVELOPER_SUCCESS = "GET_DEVELOPER_SUCCESS";
export const GET_SELECTED_DEVELOPER_SUCCESS = "GET_SELECTED_DEVELOPER_SUCCESS";
export const GET_DEVELOPER_ERROR = "GET_DEVELOPER_ERROR";
export const GET_SELECTED_DEVELOPER = "GET_SELECTED_DEVELOPER";

export const GET_AGENCY = "GET_AGENCY";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_SELECTED_AGENCY_SUCCESS = "GET_SELECTED_AGENCY_SUCCESS";
export const GET_AGENCY_ERROR = "GET_AGENCY_ERROR";
export const GET_SELECTED_AGENCY = "GET_SELECTED_AGENCY";

export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_ERROR = "GET_REVIEWS_ERROR";
export const SAVE_REVIEW = "SAVE_REVIEW";
export const SAVE_REVIEW_SUCCESS = "SAVE_REVIEW_SUCCESS";
