import {
  GET_AGENCY,
  GET_AGENCY_SUCCESS,
  GET_SELECTED_AGENCY_SUCCESS,
  GET_AGENCY_ERROR,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  selectedSuggestions: [],
  suggestions: [],
  errorMessage: null,
};

// Reducers (Modifies The State And Returns A New State)
const AgencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENCY:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_AGENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suggestions: action.data,
      };
    case GET_SELECTED_AGENCY_SUCCESS:
      return {
        ...state,
        selectedSuggestions: action.data,
      };
    case GET_AGENCY_ERROR:
      return {
        ...state,
        isLoading: false,
        suggestions: [],
        errorMessage: action.message,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default AgencyReducer;
