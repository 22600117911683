import { put, takeEvery, call, all } from "redux-saga/effects";
import { GET_SOCIAL_PAGE, CREATE_SOCIAL_PAGE } from "../constants";
import {
  getSocialPageSuccess,
  createSocialPageSuccess,
  createSocialPageError,
} from "./../actions/Social";
import Fetch from "../../libs/fetch";
import { SOCIAL_PAGE_URL } from "../api";

export function* getSocialPage(options) {
  try {
    const response = yield call(Fetch, {
      url: SOCIAL_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: options.data,
    });
    yield put(getSocialPageSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* createSocialPage(options) {
  try {
    const response = yield call(Fetch, {
      url: SOCIAL_PAGE_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(options.data),
    });

    if (response.status > 300) {
      throw response.message;
    } else {
      yield put(createSocialPageSuccess(response));
    }
  } catch (error) {
    yield put(createSocialPageError(error));
  }
}

export default function* SocialPageSaga() {
  yield takeEvery(GET_SOCIAL_PAGE, getSocialPage);
  yield takeEvery(CREATE_SOCIAL_PAGE, createSocialPage);
}
